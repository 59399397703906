import horizontalFull from '../tmb_logo_full.svg';
import vipQRCode from '../tmb_membership_qr_code.png';
import Footer from './Footer'

function VIPPage() {
    return (
        <div className="pt-[68px] object-contain sm:object-scale-down max-h-full flex flex-col content-center pt-4 overflow-y-scroll">
            <div className="mb-12 place-content-center flex flex-col justify-center object-contain">
                <img id="landing-page-logo" alt="landing-page-logo" className="mx-auto py-4 px-4 w-full h-auto md:w-auto sm:max-w-[640px] object-contain" src={horizontalFull}></img>
                <h1 id="first-listeners-club" className="text-3xl sm:text-4xl text-center">
                    First Listener's Club - VIP Membership
                </h1>
            </div>
            <div id="vip-benefits" className="mx-12 lg:mx-auto py-4 flex flex-col justify-center items-center h-full">
                <h2 id="vip-text" className="w-[100%] text-left pb-2 uppercase">
                    Membership Benefits: 
                </h2>
                <div id="vip-text" className="w-[100%] text-left my-1">
                    - "Gold Top" VIP First Listener's Club Commemorative Card
                </div>
                <div id="vip-text" className="w-[100%] text-left my-1">
                    - Access to two VIP ONLY events per year
                </div>
                <div id="vip-text" className="w-[100%] text-left my-1">
                    - Early Access to purchase event tickets (some but not all shows will have seat selection options)
                </div>
                <div id="vip-text" className="w-[100%] text-left my-1">
                    - VIP Meet & Greet with select artists (at artist's discretion if they are willing to participate)
                </div>
                <div id="vip-text" className="w-[100%] text-left my-1">
                    - 10% off MUSIC BOX merchandise purchases over $30.00
                </div>
                <div id="vip-text" className="w-[100%] text-left my-1">
                    - Discounted Couple Memberships
                </div>
                <div id="vip-text" className="w-[100%] text-left mt-1">
                    - One FREE raffle ticket for an exclusive MUSIC BOX stage-played, autographed guitar (by the performers of that year)
                </div>
                <div id="vip-text" className="w-[100%] text-left mb-1 italic">
                        **Drawing will be at a live event to be held in December, winner does not have to be present
                </div>
            </div>
            <div id="vip-grid" className="grid sm:flex justify-left sm:justify-center grid-rows-2 sm:columns-2 pb-4 mx-8 md:mx-16">
                <div id="mx-0 lg:mx-6 max-h-[250px] sm:max-h-full row-start-1 sm:col-start">
                    <div id="vip-text" className="mx-4 pt-2 pb-2 uppercase">
                        Membership Costs:
                    </div>
                    <div id="membership-costs-text" className="mx-4 container rows-2 md:columns-2">
                        <div className="max-h-[250px] col-start pb-4">
                            <h2 id="vip-text" className="pb-2 text-2xl sm:text-xl">
                                12 Mo. Membership:
                            </h2>
                            <div id="vip-text" className="text-3xl sm:text-2xl">
                                $135.00 / person
                            </div>
                            <div id="vip-text" className="text-3xl sm:text-2xl">
                                $200.00 for two
                            </div>
                        </div>
                        <div className="col-end">
                            <h2 id="vip-text" className="pb-2 text-2xl sm:text-xl">
                                12 Mo. Renewal:
                            </h2>
                            <div id="vip-text" className="text-3xl sm:text-2xl">
                                $100.00 / person
                            </div>
                            <div id="vip-text" className="text-3xl sm:text-2xl">
                                $185.00 for two
                            </div>
                        </div>
                        <div className="row-start-2">
                        </div>
                    </div>
                </div>
                <div id="pay-here" className="mx-4 lg:mx-6 sm:col-end">
                    <h2 id="vip-text" className="pt-2 pb-2 uppercase">
                        Pay Here:
                    </h2>
                    <div id="vip-text" className="text-2xl">
                        VENMO: @TheMusicBoxRockford
                    </div>
                    <div id="vip-text" className="text-xl">
                        or via the QR Code
                    </div>
                    <img alt="qr-code" id="qr-code" className="" src={vipQRCode}></img>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default VIPPage;