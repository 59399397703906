import Nav from './Nav';
import { NavLink } from "react-router-dom";
import brassLogomarkBird from "../tmb_logomark_brass.svg";
import brassHorizontalLogo from "../tmb_horizontal_brass.svg";


function Header() {
    return (
        <header className="sticky top-0 z-[1] mx-auto flex flex-wrap w-full items-center justify-between p-6 bg-gradient-to-b from-black from-30%">
            <NavLink to="/" className="hidden md:flex justify-start">
                <img className="mr-auto h-6 sm:h-9" src={brassHorizontalLogo} alt="nav-logo"></img>
            </NavLink>
            <NavLink to="/" className="justify-center">
                <img className="ml-0 md:ml-12 h-8 sm:h-9" src={brassLogomarkBird} alt="nav-logomark"></img>
            </NavLink>
            <Nav />
        </header>
    )
};

export default Header;
