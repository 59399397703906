import React, { useEffect, useRef } from 'react';

const apiKey = process.env.REACT_APP_DICE_KEY;
const partnerId = process.env.REACT_APP_DICE_PARTNER_ID;

const EventList = () => {
  const iframeRef = useRef(null);

  useEffect(() => {
    const iframe = iframeRef.current;
    if (!iframe) return;

    const scriptContent = `
      (function() {
        const script = document.createElement('script');
        script.src = 'https://widgets.dice.fm/dice-event-list-widget.js';
        script.type = 'text/javascript';
        script.async = true;
        script.onload = function() {
          window.DiceEventListWidget.create({
            information: 'full',
            eventTitle: 'event',
            showImages: true,
            showAudio: true,
            showNewBadge: false,
            hidePostponed: false,
            hideCancelled: true,
            layout: 'gallery',
            roundButtons: false,
            theme: 'dark',
            fontFamily: 'inherit',
            partnerId: '${partnerId}',
            apiKey: '${apiKey}',
            version: 2,
            showPrice: true,
            highlightColour: '#B39055',
            promoters: ['Music Box of Rockford LLC']
          });
        };
        document.body.appendChild(script);
      })();
    `;

    iframe.contentWindow.document.open();
    iframe.contentWindow.document.write(`
      <!DOCTYPE html>
      <html lang="en">
      <head><title>DICE Widget</title></head>
      <body>
        <div id="dice-event-list-widget"></div>
        <script type="text/javascript">${scriptContent}</script>
      </body>
      </html>
    `);
    iframe.contentWindow.document.close();
  }, []);

  return (
    <iframe
        id="event-list-iframe"
        ref={iframeRef}
        title="DICE Event List"
    />
  );
};

export default React.memo(EventList);

