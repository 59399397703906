import { NavLink } from "react-router-dom";
import { useState } from 'react';
import { AiOutlineClose, AiOutlineMenu } from 'react-icons/ai'

const NavLinks = () => {
  return (
    <>
      <NavLink id="nav-vip" to="/vip" className="block cursor-pointer uppercase tracking-widest">Join Now - VIP Membership</NavLink>
    </>
  )
}


const Nav = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen)
  };

  return (
    <>
      <nav className="flex justify-end overflow-hidden">
        <div className="hidden w-full justify-between md:flex">
          <NavLinks />
        </div>
        <div className="md:hidden text-vintage-brass hover:text-antique-beige">
          <button onClick={toggleMenu} type="button">{isOpen ? <AiOutlineClose /> : <AiOutlineMenu />}</button>
        </div>
      </nav>
      {isOpen && (
        <div className="md:hidden flex basis-full flex-col items-center"> 
          <ul>
            <li>
              <NavLink id="nav-vip" to="/vip" className="block md:hidden cursor-pointer uppercase tracking-widest">Join Now - VIP Membership</NavLink>
            </li>
          </ul>
        </div>
      )}
    </>
  );
};

export default Nav;